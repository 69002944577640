<template>
  <v-container class="pa-0">
    <v-row
      class="header"
      justify="space-around"
    >
      <img
        src="../../assets/image/icon/back.png"
        alt="뒤로가기"
        type="button"
        @click="$router.replace('/task')"
      >
      <span class="span-back"> &nbsp;뒤로 </span>
      <v-spacer />
      <v-col
        class="header-img ma-0 pa-0"
        align-self="center"
        cols="auto"
      >
        <img
          alt="추가"
          src="../../assets/image/icon/add.png"
          type="button"
          @click="$router.replace('/task/plan')"
        >
      </v-col>
      <span class="span-task-add">&nbsp;플랜 생성&nbsp;</span>
    </v-row>
    <v-row class="content-container mt-10">
      <task-component/>
    </v-row>
  </v-container>
</template>

<script>
import TaskComponent from "@/components/controller/task.vue";

export default {
  name: 'Work',
  components: {TaskComponent},
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.content-container {
  height: 80vh;
  overflow: auto;
}

.span-back {
  color: #ffffff;
  font-size: 60px !important;
}

.span-task-add  {
  justify-self: right;
  color: #ffffff;
  font-size: 60px !important;
}

@media (max-width: 1700px) {
  .header img {
    width: 50px;
    height: 50px;
  }

  .span-back {
    font-size: 40px !important;
  }

  .span-task-add {
    font-size: 40px !important;
  }

}

@media (max-width: 1140px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .span-back {
    font-size: 20px !important;
  }

  .span-task-add {
    font-size: 20px !important;
  }

}
</style>
